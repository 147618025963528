$(document).on('turbolinks:load', function() {
    //filter options hide
    $('#activityFilterContent').hide();
    $('.reservation-list-header .person-name').hide();
    //filter options display
    $('#activityFilterBtn').on('click', function() {
        if ($('#activityFilterBtn').hasClass('selected')) {
            $('#activityFilterBtn').removeClass('selected');
            if ((($('.start-date').text() != 'From Date') && ($('.end-date').text() != 'To Date')) || (($('#select2-tags-container').text() != ''))) {
                $('#activityFilterBtn').addClass('filtered');
            }
            $('#activityFilterContent, .activity-fade').hide();
        } else {
            $('#activityFilterBtn').addClass('selected');
            if($('#activityFilterBtn').hasClass('filtered')) {
                $('#activityFilterBtn').removeClass('filtered');
            }
            $('#activityFilterContent, .activity-fade').show();
        }
    });
    // get the Video Duration Functions
    getMultipleVideoDuration();
    getVideoDuration();


    $(".profile-edit").on('click', function() {
        if ($(".profile-information").hasClass('profile-form-edit')) {
            $(".profile-information").removeClass('profile-form-edit');
            $(".profile-information").find("input").addClass("form-control-plaintext");
            $(".profile-information").find("input").removeClass("form-control");
            $(".profile-information").closest("input").attr('readonly',true);
            $(".other-contact-details").find(".collapse-btn").show();
            $(".other-contact-details").find(".other-contacts").addClass("col-lg-8");
            $(".other-contact-details").find(".collapsed-content").addClass("collapse");
        } else {
            $(".profile-information").addClass('profile-form-edit');
            $(".profile-information").find("input").removeClass("form-control-plaintext");
            $(".profile-information").find("input").addClass("form-control");
            $(".profile-information").find("input").attr('readonly',false);
            $(".other-contact-details").find(".collapse-btn").hide();
            $(".other-contact-details").find(".other-contacts").removeClass("col-lg-8");
            $(".other-contact-details").find(".collapsed-content").removeClass("collapse");
        }
    });

    $(".number-only").on("keypress keyup blur",function (event) {
        $(this).val($(this).val().replace(/[^\d].+/, ""));
        if ((event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    $('.collapse-btn').on('click',function (){
        console.log('hi')
        if ($(this).hasClass('collapsed')) {
            $(this).siblings('.edit-profile').addClass("right-align")
            $(this).siblings('.w-25').addClass('w-100')
        }else{
            $(this).siblings('.edit-profile').removeClass("right-align")
            $(this).siblings('.w-25').removeClass('w-100')
        }
    })
    //order list
    //place order collapse
    $(".edit-description").on('click', function() {
        if ($(this).hasClass('collapsed')) {
            $(this).closest(".card-header").removeClass("collapsed");
        } else {
            $(this).closest(".card-header").addClass("collapsed");
        }
    });
});


// Get Video Source and value Pass it to the Modal
$('.videoResource').click(function() {
    $('#videoPreview').attr('src', $(this).attr('src'));
    $('#videoPreview')[0].play();
    $('.btn-download, .btn-mobile').attr('href', $(this).attr('src'));
});

// Paused Video When modal close
$('.modal-video').on('hidden.bs.modal', function(e) {
    $('#videoPreview')[0].pause();
});

// Paused and Play video on Click
$('#videoHighlights').click(function() {
    this.paused ? this.play() : this.pause();
    // $('.play-icon').toggle('slow', 1.5);
    if (this.paused) {
        $('.play-icon').fadeIn();
    } else {
        $('.play-icon').fadeOut(500);
    }
});

// After Video complete show play icon
$('#videoHighlights').bind('ended', function() {
    $('.play-icon').fadeIn();
});

// Play Video on click play icon
$('.play-icon').click(function() {
    $('#videoHighlights')[0].play();
    $('.play-icon').fadeOut(500);
});

function getMultipleVideoDuration() {
    var video = $('.videoResource');
    video.each(function() {
        $(this).on('loadedmetadata', function(e) {
            var total = e.target.duration.toFixed(0);
            var minutes = Math.floor(total / 60);
            var seconds = total % 60;

            $('.duration .time').text(minutes + ":" + seconds + "s");
            if ((minutes < 10) && (seconds < 10)) {
                $(this).siblings('.video-duration').html('0' + minutes + ':' + '0' + seconds.toFixed(0));
            } else if ((minutes > 10) && (seconds < 10)) {
                $(this).siblings('.video-duration').html(minutes + ':' + '0' + seconds.toFixed(0));
            } else if ((minutes < 10) && (seconds > 10)) {
                $(this).siblings('.video-duration').html('0' + minutes + ':' + seconds.toFixed(0));
            } else {
                $(this).siblings('.video-duration').html(minutes + ':' + seconds.toFixed(0));
            }
        });
    });
}

function getVideoDuration() {
    var video = $('#videoHighlights');
    video.each(function() {
        $(this).on('loadedmetadata', function(e) {
            var total = e.target.duration.toFixed(0);
            var minutes = Math.floor(total / 60);
            var seconds = total % 60;
            $('.duration .time').text(minutes + ":" + seconds + "s");
            if ((minutes < 10) && (seconds < 10)) {
                $('.video-duration').html('0' + minutes + ':' + '0' + seconds.toFixed(0) + 'sec');
            } else if ((minutes > 10) && (seconds < 10)) {
                $('.video-duration').html(minutes + ':' + '0' + seconds.toFixed(0) + 'sec');
            } else if ((minutes < 10) && (seconds > 10)) {
                $('.video-duration').html('0' + minutes + ':' + seconds.toFixed(0) + 'sec');
            } else {
                $('.video-duration').html(minutes + ':' + seconds.toFixed(0) + 'sec');
            }
        });
    });
};