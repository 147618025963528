$(document).on('turbolinks:load', function() {
    //bootstrap datepicker
    $(".datepicker-modal").datepicker({
        autoclose: false,
        daysOfWeekHighlighted: '06',
        multidate: 2
    });

    $('#search-button').on('click', function(){
        if ((($('.start-date').text() != 'From Date') && ($('.end-date').text() != 'To Date')) && (($('#select2-tags-container').text() != '') || ($('#select2-tags-container').text() == '')) ||
            (($('.start-date').text() == 'From Date') && ($('.end-date').text() == 'To Date')) && (($('#select2-tags-container').text() != '') || ($('#select2-tags-container').text() == ''))) {
            $('form#submit-form').submit();
        } else {
            if($('.start-date').text() != 'From Date') {
                $('#error-activity').html("Please select To Date");
            }
            else {
                $('#error-activity').html("Please select From Date");
            }
        }
    });
    $(".datepicker-daily-highlights-modal").datepicker({
        autoclose: false,
        daysOfWeekHighlighted: '06',
        multidate: 1,
        format: 'yyyy-mm-dd'
    });

    $(".reservation-datepicker-modal").datepicker({
        autoclose: false,
        daysOfWeekHighlighted: '06',
        multidate: 1,
        format: 'yyyy-mm-dd'
    });

    $(".datepicker-modal").on('changeDate', function(e) {
        dateAppend(e);
    });
    $(".datepicker-daily-highlights-modal").on('changeDate', function(e) {
        dateAppendDailyHighlights(e);
        dateAppendDailyHighlightsNew(e);
    });
    $(".datepicker-daily-highlights-modal-order").on('changeDate', function(e) {
        dateAppendDailyHighlights(e);
    });

    $(".reservation-datepicker-modal").on('changeDate', function(e) {
        dateAppendReservation(e);
    });

    addDatePickerIcon();
    addDayName();
    addDatePickerIconDailyHighlights();
    addDayNameDailyHighlights();
    $(".datepicker-switch").parent().css("justify-content", "space-between");
    $(window).resize(function() {
        addDatePickerIcon();
        addDayName();
        addDatePickerIconDailyHighlights();
        addDayNameDailyHighlights();
    });

    $('.datepicker-switch').on('click',function(){
        $('.datepicker-switch').off('click');
    });
});

//changing datepicker Icon
function addDatePickerIcon() {
    if ($(window).innerWidth() > 767) {
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .next").html('<div class="img-box"><svg width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 30L18.0894 15.7307C18.5608 15.3126 18.5327 14.5678 18.031 14.1865L2 2" stroke="#39C8AF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg></div>');
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .prev").html('<div class="img-box"><svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 2L2.98784 16.2495C2.48724 16.6688 2.5163 17.4476 3.04675 17.8284L20 30" stroke="#39C8AF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg></div>');
    } else {
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .prev").html('<div class="img-box"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8L10 12L14 16" stroke="#C2C5D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="12.5" cy="12.5" r="12" stroke="#E7E7E7"/></svg></div>');
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .next").html('<div class="img-box"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 8L15 12L11 16" stroke="#C2C5D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle r="12" transform="matrix(-1 0 0 1 12.5 12.5)" stroke="#E7E7E7"/></svg></div>');
    }
}

//adding days
function addDayName() {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysShortName = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    if ($(window).innerWidth() > 767) {
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .dow").each(function(i) {
            $(this).html(daysShortName[i]);
        });
    } else {
        $("#dateTimeModal .datepicker-modal .datepicker-days .table-condensed .dow").each(function(i) {
            $(this).html(days[i]);
        });
    }
}

//changing datepicker Icon in Daily Highlights
function addDatePickerIconDailyHighlights() {
    if ($(window).innerWidth() > 767) {
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .next").html('<div class="img-box"><svg width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 30L18.0894 15.7307C18.5608 15.3126 18.5327 14.5678 18.031 14.1865L2 2" stroke="#39C8AF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg></div>');
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .prev").html('<div class="img-box"><svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 2L2.98784 16.2495C2.48724 16.6688 2.5163 17.4476 3.04675 17.8284L20 30" stroke="#39C8AF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg></div>');
    } else {
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .prev").html('<div class="img-box"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8L10 12L14 16" stroke="#C2C5D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="12.5" cy="12.5" r="12" stroke="#E7E7E7"/></svg></div>');
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .next").html('<div class="img-box"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 8L15 12L11 16" stroke="#C2C5D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle r="12" transform="matrix(-1 0 0 1 12.5 12.5)" stroke="#E7E7E7"/></svg></div>');
    }
}

//adding days in Daily Highlights
function addDayNameDailyHighlights() {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysShortName = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    if ($(window).innerWidth() > 767) {
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .dow").each(function(i) {
            $(this).html(daysShortName[i]);
        });
    } else {
        $("#dateTimeModal .datepicker-daily-highlights-modal .datepicker-days .table-condensed .dow").each(function(i) {
            $(this).html(days[i]);
        });
    }
}

//date Append
var startDate = [];
var endDate = [];
function getOrdinalSuffix(number) {
    if (number >= 11 && number <= 13) {
        return 'th';
    }
    switch (number % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}
function dateAppend(event) {
    var dateList = {
        date1: event.format('yyyy-mm-dd'),
        date2: event.format(' M ') + event.format('dd') + getOrdinalSuffix(event.format('dd')),
        day: event.format('D')
    };
    if (dateList.date1 === "") {
        startDate = [];
        endDate = [];
        fromDate = [];
        toDate=[];
    } else {
        if (startDate.length === 0) {
            startDate.push(dateList);
        } else if (endDate.length === 0) {
            endDate.push(dateList);
        } else {
            if (JSON.stringify(startDate) === JSON.stringify([dateList])) {
                endDate = [];
            } else if (JSON.stringify(endDate) === JSON.stringify([dateList])) {
                startDate = [];
            } else {
                [startDate, endDate] = [endDate, startDate];
                endDate = [dateList];
            }
        }
        var fromDate = startDate,
            toDate = endDate;
        if (fromDate.length && toDate.length) {
            if (fromDate[0].date1 >= toDate[0].date1) {
                [fromDate, toDate] = [toDate, fromDate];
            }
        }
    }
    if (fromDate.length) {
        $("#activityFilterContent .start-date-filter").val(fromDate[0].date1);
        $("#dailyHighlightsFilter .start-date-filter").val(fromDate[0].date1);
        $("#dateTimeModal .modal-header .start-date").html('<span class="selected-date"><span class="selected-day">' + fromDate[0].day + '</span>' + fromDate[0].date2 + '</span>');
    } else {
        $("#activityFilterContent .start-date-filter").val();
        $("#dailyHighlightsFilter .start-date-filter").val();
        $("#dateTimeModal .modal-header .start-date").html('From Date');
        $("#startDate").val('');
    }
    if (toDate.length) {
        $("#activityFilterContent .end-date-filter").val(toDate[0].date1);
        $("#dateTimeModal .modal-header .end-date").html('<span class="selected-date"><span class="selected-day">' + toDate[0].day + '</span>' + toDate[0].date2 + '</span>');
    } else {
        $("#activityFilterContent .end-date-filter").val();
        $("#dateTimeModal .modal-header .end-date").html('To Date');
        $("#endDate").val('');
    }
}

function dateAppendDailyHighlights(event){
    var getDate = {
        date: event.format(' M ') + event.format('dd') + getOrdinalSuffix(event.format('dd')),
        day: event.format('DD')
    };
    if(getDate != ''){
        $("#dateTimeModal .modal-header .selectedDate").html('<span><span class="get-day">' + getDate.day  + ',' + '</span>' + getDate.date + '</span>');
    }
}

function dateAppendReservation(event) {
    var getDate = {
        date: event.format(' M ') + event.format('dd') + getOrdinalSuffix(event.format('dd')),
        day: event.format('DD')
    };
    if (getDate != '') {
        $("#dateTimeModal .modal-header .start-date").html('<span><span class="get-day">' + getDate.day + ',' + '</span>' + getDate.date + '</span>');
    }

    var dateList = {
        date1: event.format('yyyy-mm-dd'),
        date2: event.format(' M ') + event.format('dd') + getOrdinalSuffix(event.format('dd')),
        day: event.format('DD')
    };
    if (dateList.date1 === "") {
        startDate = [];
        fromDate = [];
    } else {
        if (startDate.length === 0) {
            startDate.push(dateList);
        } else {
            if (JSON.stringify(startDate) === JSON.stringify([dateList])) {
                startDate = [];
            } else {
                startDate = [dateList];
            }
        }
        var fromDate = startDate;
        if (fromDate.length) {
            [fromDate] = [fromDate];
        }
    }

    if (fromDate.length) {
        $("#reservationDate .start-date-filter").val(fromDate[0].date1);
        $("#dateTimeModal .modal-header .start-date").html('<span class="start-date"><span class="start-date">' + fromDate[0].day + '</span>' + fromDate[0].date2 + '</span>');
    }
}